import axios from "axios";

const service = axios.create({
  baseURL:
    "https://api-brain.itic-sci.com/kg-industry/chatsearch/chatsearch-api",
  timeout: 60 * 1000,
  headers: {
    "content-Type": "application/json",
  },
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      console.log(res.message);
    }
    return res;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default service;
